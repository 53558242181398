$pd-color-white: #ffffff;
$pd-color-light-gray: #f2f5f7;
$pd-color-gray: #cccccc;
$pd-color-dark-gray: #777777;
$pd-color-darker-gray: #444444;
$pd-color-black: #222222;

// color theme
$primary-color: $pd-color-darker-gray;
$secondary-color: lighten($primary-color, 20);

// lopez color pallete
$pd-color-lighter-green: lighten(#3db68a, 40);
$pd-color-light-green: #969dca;
$pd-color-green: #3db68a;
$pd-color-dark-green: #0f8344;
