@import 'scss/utils/index';
@import 'scss/variables/index';
@import 'scss/global/index';

html {
  font-family: 'Brandon', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;
}

a {
  color: inherit;

  &:hover {
    color: $primary-color;
  }
}

.pd-cms {
  min-height: 100vh;
}
