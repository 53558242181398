@import 'scss/variables/spacing';

.pd-align {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }
}
